// import { createApp, ref } from "vue";
// import { createApp, reactive, ref, onMounted } from "vue/dist/vue.esm-bundler";
// import Vue from "vue";
import Vue from "vue";

import { BootstrapVue, IconsPlugin, ModalPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// import "./css/index.sass";

import store from "./store";

class Initializer {
  constructor() {
    this.vueComponents = [];
    this.start();
  }

  start() {
    Vue.use(BootstrapVue);
    Vue.use(IconsPlugin);
    Vue.use(ModalPlugin);

    this.initialInstances();
  }

  initialInstances() {
    document.addEventListener("DOMContentLoaded", () => {
      let templates = document.querySelectorAll("[data-vue]");

      templates.forEach(async (template) => {
        const vueName = template.dataset.vue;

        const initializer = await import(`./initializers/${vueName}.js`);

        let vueComponent = new Vue(
          Object.assign(initializer.default, {
            el: template,
            store,
          })
        );

        this.vueComponents.push(vueComponent);
      });
    });
  }
}

export default Initializer;
